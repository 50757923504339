<template>
	<div class="page-step-brand-info">
		<div class="custom-headline">入驻申请</div>
		<el-card>
			<Step v-model="stepActiveIndex"></Step>
			<div class="custom-card">
				<div class="custom-title">品牌信息</div>
				<el-form ref="brandInfoForm" :model="brandInfoForm" :rules="brandInfoFormRules" labelWidth="220px">
					<!-- 品牌信息 -->
					<el-row>
						<el-col :span="8">
							<el-form-item label="品牌名称" prop="zhName">
								<span>{{ brandInfoForm.zhName }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="3">
							<el-button class="custom-send-msg-btn" @click="toggleBrandSelectDialogFlag">选择品牌</el-button>
						</el-col>
						<el-col>
							<el-form-item label="品牌级别" prop="popLevel">
								<span v-if="brandInfoForm.popLevel !== '' && brandInfoForm.popLevel == 1">国际品牌</span>
								<span v-if="brandInfoForm.popLevel == 2">国内连锁品牌</span>
								<span v-if="brandInfoForm.popLevel == 3">国内区域品牌</span>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item label="品牌LOGO" prop="logo">
								<el-image v-if="brandInfoForm.logo" style="width: 100px; height: 100px"
									:src="brandInfoForm.logo" fit="scale-down">
								</el-image>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="品牌业态" prop="_brandBizFormCode">
                <format-cascader @change="handleCascaderChanged" style="width:100%"></format-cascader>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item label="商品源原产地" prop="goodOrigin">
								<el-radio-group v-model="brandInfoForm.goodOrigin">
									<el-radio v-for="ite in goodOriginOptions" :key="ite.value" :label="ite.value">
										{{ ite.label }}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item label="品牌方是否为个人" prop="brandIsPersonal">
								<el-radio-group v-model="brandInfoForm.brandIsPersonal">
									<el-radio v-for="ite in brandIsPersonalOptions" :key="ite.value" :label="ite.value">
										{{ ite.label }}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item label="品牌授权方是否是境内公司" prop="brandAuthIsDomestic">
								<el-radio-group v-model="brandInfoForm.brandAuthIsDomestic">
									<el-radio v-for="ite in brandAuthIsDomesticOptions" :key="ite.value"
										:label="ite.value">
										{{ ite.label }}</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<!-- 经营资质 -->
					<div class="custom-title">经营资质</div>
					<el-row>
						<el-col class="seniorty-table">
							<el-table border :data="seniorityDescriptionTable">
								<el-table-column prop="status" label="经营资质列表"></el-table-column>
								<el-table-column prop="desc" label="备注"></el-table-column>
							</el-table>
						</el-col>
						<el-col>
							<el-form-item label="商标证上传" prop="_tradeMarkPic">
								<el-upload ref="tradeUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="brandInfoForm._trade.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._trade.limit" :multiple="uploadConfig._trade.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_trade')"
									:on-success="(res) => handleUploadSuccess(res,'_trade', '_tradeMarkPic')"
									:before-remove="(file) => handleFileRemove(file, '_trade', '_tradeMarkPic')"
									:on-exceed="() => hanleUploadExceed('_trade')" :on-preview="handlePicturePreview"
									:class="{disabled: uploadConfig._trade.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip custom-upload-mark">
										<div v-for="(r, index) in uploadConfig._trade.remark" :key="index"
											class="upload-mark">{{r}}</div>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item label="授权书上传" prop="_authorizationPic">
								<el-upload ref="authUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="brandInfoForm._auth.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._auth.limit" :multiple="uploadConfig._auth.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_auth')"
									:on-success="(res) => handleUploadSuccess(res,'_auth', '_authorizationPic')"
									:before-remove="(file) => handleFileRemove(file, '_auth', '_authorizationPic')"
									:on-exceed="() => hanleUploadExceed('_auth')" :on-preview="handlePicturePreview"
									:class="{disabled: uploadConfig._auth.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip custom-upload-mark">
										<div v-for="(r, index) in uploadConfig._auth.remark" :key="index"
											class="upload-mark">
											{{r}}</div>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
						<el-col>
							<el-form-item label="其他资质" prop="_otherPic">
								<el-upload ref="otherUpload" list-type="picture-card" :action="uploadUrl"
									:file-list="brandInfoForm._other.fileList" :accept="uploadConfig.base.accept"
									:limit="uploadConfig._other.limit" :multiple="uploadConfig._other.multiple"
									:before-upload="(file) => handleBeforeUpload(file, '_other')"
									:on-success="(res) => handleUploadSuccess(res,'_other', '_otherPic')"
									:before-remove="(file) => handleFileRemove(file, '_other', '_otherPic')"
									:on-exceed="() => hanleUploadExceed('_other')" :on-preview="handlePicturePreview"
									:class="{disabled: uploadConfig._other.disabled}">
									<i class="el-icon-plus"></i>
									<div slot="tip" class="el-upload__tip custom-upload-mark">
										<div v-for="(r, index) in uploadConfig._other.remark" :key="index"
											class="upload-mark">{{r}}</div>
									</div>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="custom-action-box">
					<el-button class="btn" size="small reset" @click="handleReset">重置</el-button>
					<el-button class="btn" size="small reset" @click="jump2beforeStep">上一步</el-button>
					<el-button class="btn" size="small" type="primary" :loading="submitPendingFlag"
						@click="jump2nextStep">提交</el-button>
				</div>
			</div>
		</el-card>

		<!-- 弹窗 - 品牌列表 -->
		<el-dialog title="选择品牌" top="5vh" :visible.sync="selectBrandDialogFlag" :close-on-click-modal="false"
			width="980px">
			<!-- :before-close="handleClose" -->
			<el-form ref="bandListForm" :inline="true" label-width="80px">
				<el-form-item label="品牌名称" prop="zhName">
					<el-input v-model.trim="queryBrandParams.zhName"></el-input>
				</el-form-item>
				<el-form-item label="品牌级别" prop="popLevel">
					<el-select v-model="queryBrandParams.popLevel" placeholder="请选择">
						<el-option v-for="b in brandLevelOptions" :key="b.value" :label="b.label" :value="b.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button class="query-btn" size="small" type="primary" @click="getBrandList(true)">查询</el-button>
					<el-button class="reset-btn" size="small" @click="resetBrandListQueryParams">重置</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="brandTableLoading" :data="brandList" height="50vh" stripe border>
				<el-table-column label="序号" type="index" width="100" align="center">
				</el-table-column>
				<el-table-column label="品牌名称" prop="zhName" align="center">
				</el-table-column>
				<el-table-column label="品牌级别" prop="popLevel" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.popLevel == 1">国际品牌</span>
						<span v-if="scope.row.popLevel == 2">国内连锁品牌</span>
						<span v-if="scope.row.popLevel == 3">国内区域品牌</span>
					</template>
				</el-table-column>
				<el-table-column label="品牌LOGO" width="220" align="center">
					<template slot-scope="scope">
						<el-image v-if="scope.row.logo" style="width: 50px; height: 50px" :src="scope.row.logo"
							fit="scale-down"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100" align="center">
					<template slot-scope="scope">
						<el-link :underline="false" type="primary" @click="handleBrandSelected(scope.row)">选择</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handlePageSizeChange" @current-change="handlePageNumChange"
				:page-sizes="[10, 20, 50]" :page-size="queryBrandParams.pageSize"
				:current-page="queryBrandParams.current" layout="total, sizes, prev, pager, next, jumper"
				:total="queryBrandParams.pageTotal">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
				<div class="custom-action-box form">
					<el-button class="btn" type="primary" size="mini" @click="newBrandFormDialogFlag=true">添加新品牌
					</el-button>
				</div>
			</span>
		</el-dialog>
		<!-- 弹窗 - 添加品牌 -->
		<el-dialog title="添加品牌" top="10vh" :visible.sync="newBrandFormDialogFlag" :close-on-click-modal="false"
			width="50%" @close="resetNewBrandFormDialogFlag">
			<el-form ref="newBrandForm" :model="newBrandForm" :rules="newBrandFormRules" label-width="100px">
				<el-row>
					<el-col :span="16">
						<el-form-item label="品牌名称:" prop="zhName">
							<el-input v-model.trim="newBrandForm.zhName" placeholder="请输入品牌名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="16">
						<el-form-item label="品牌级别:" prop="popLevel">
							<el-select style="width: 100%" v-model="newBrandForm.popLevel" placeholder="请选择">
								<el-option v-for="item in brandLevelOptions" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col>
						<el-form-item label="品牌LOGO:" prop="logo">
							<el-upload ref="newBrandUpload" list-type="picture-card" :action="uploadUrl"
								:accept="uploadConfig.base.accept" :limit="uploadConfig._brand.limit"
								:multiple="uploadConfig._brand.multiple"
								:before-upload="(file) => handleBeforeUpload(file, '_brand')"
								:on-success="(res) => handleUploadSuccess(res,'_brand', '_logo')"
								:before-remove="(file) => handleFileRemove(file, '_brand', '_logo')"
								:on-exceed="() => hanleUploadExceed('_brand')" :on-preview="handlePicturePreview"
								:class="{disabled: uploadConfig._brand.disabled}">
								<i class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">
									<p v-for="(r, index) in uploadConfig._brand.remark" :key="index"
										class="upload-mark">{{r}}</p>
								</div>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button class="btn" @click="newBrandFormDialogFlag=false">取 消</el-button>
				<el-button class="btn" type="primary" :loading="submitNewBrandPendingFlag" @click="submitNewBrandForm">确
					定
				</el-button>
			</span>
		</el-dialog>
		<!-- 弹窗 - 图片预览 -->
		<el-dialog :visible.sync="uploadConfig.base.previewPictureFlag">
			<img width="100%" :src="uploadConfig.base.previewPictureSrc" />
		</el-dialog>
	</div>
</template>

<script>
import Step from "../components/Step";
import FormatCascader from "@/components/FormatCascader";
import {
	getJoinAllFormInfo,
	getBrandList,
	getBrandDetailById,
	submitNewBrandForm,
	submitBrandInfoForm,
} from "@/api/api-join-mall";
import {
	// getCaByLevel,
	getUploadFile,
	// getBrandImageId,
} from "@/api";
// import { makeElementTree } from "@/utils/util";
import { mapGetters } from "vuex";
import { BarndFrom } from "@/utils/cache";
export default {
	components: {
		Step,
    FormatCascader
	},
	computed: {
		...mapGetters(["entryType", "goodOriginList", "yesOrNo", "brandLevel"]),
		uploadUrl() {
			return `/${
				process.env.VUE_APP_MODE === "production" ? "api-match" : "api"
			}/gm-nxcloud-resource/api/nxcloud/res/upload`;
		},
	},
	data() {
		return {
			stepActiveIndex: [0, 2],
			// 上传配置
			uploadConfig: {
				base: {
					accept: ".jpg,.jpeg,.png,gif",
					fileType: [
						"image/png",
						"image/jpg",
						"image/jpeg",
						"image/gif",
					],
					previewPictureFlag: false,
					previewPictureSrc: "",
				},
				_trade: {
					limit: 10,
					multiple: true,
					maxSize: 10,
					disabled: false,
					remark: [
						"图片尺寸800px*800px以上，大小10MB以内，格式png/jpg/jpeg，最多可上传10张",
						"注意:如果图片像素太小，招商人员将无法对您的资质正确评估，因此请务必按照图片要求上传",
					],
				},
				_auth: {
					limit: 5,
					multiple: true,
					maxSize: 5,
					disabled: false,
					remark: [
						"文件大小在5M以内，支持png、jpeg、gif格式",
						"若为复印件，需加盖公司红章后上传",
					],
				},
				_other: {
					limit: 5,
					multiple: true,
					maxSize: 5,
					disabled: false,
					remark: [
						"文件大小在5M以内，支持png、jpeg、gif格式",
						"若为复印件，需加盖公司红章后上传",
					],
				},
				_brand: {
					limit: 1,
					multiple: false,
					maxSize: 10,
					disabled: false,
					remark: [
						"图片尺寸800px*800px以上，大小10MB以内，格式png/jpg/jpeg，最多可上传1张",
						"注意:如果图片像素太小，招商人员将无法对您的资质正确评估，因此请务必按照图片要求上传",
					],
				},
			},
			// 选择器 - 品牌级别
			brandLevelOptions: [
				{
					label: "国际品牌",
					value: 1,
				},
				{
					label: "国内连锁品牌",
					value: 2,
				},
				{
					label: "国内区域品牌",
					value: 3,
				},
			],
			// 选择器 - 商品源原产地
			goodOriginOptions: [
				{
					label: "国产",
					value: 1,
				},
				{
					label: "进口",
					value: 2,
				},
			],
			brandIsPersonalOptions: [
				{
					label: "是",
					value: 1,
				},
				{
					label: "否",
					value: 0,
				},
			],
			// 选择器 - 是否个人
			brandAuthIsDomesticOptions: [
				{
					label: "是",
					value: 1,
				},
				{
					label: "否",
					value: 0,
				},
			],
			// 中间变量
			brandInfoFormMiddleKeyList: [
				{
					ref: "tradeUpload",
					middleKey: "_trade",
					key: "tradeMarkPic",
				},
				{
					ref: "authUpload",
					middleKey: "_auth",
					key: "authorizationPic",
				},
				{
					ref: "otherUpload",
					middleKey: "_other",
					key: "otherPic",
				},
				{
					key: "brandBizFormCode",
				},
			],
			// 表单
			brandInfoForm: {
				// 品牌信息
				brandId: "",
				zhName: "",
				popLevel: "",
				logo: "",
				logoResId: "",
        // 新业态
        newBrandBizFormCode: null,
				_brandBizFormCode: [],
				brandBizFormCode: "",
				goodOrigin: "",
				brandIsPersonal: "",
				brandAuthIsDomestic: "",
				// 经营资质
				_trade: {
					fileList: [],
				},
				_tradeMarkPic: [],
				tradeMarkPic: "",
				_auth: {
					fileList: [],
				},
				_authorizationPic: [],
				authorizationPic: "",
				_other: {
					fileList: [],
				},
				_otherPic: [],
				otherPic: "",
			},
			brandInfoFormRules: {
				// 品牌信息
				zhName: [
					{
						required: true,
						message: "请选择品牌",
						trigger: "change",
					},
				],
				popLevel: [
					{
						required: true,
						message: "请选择品牌",
						trigger: "change",
					},
				],
				_brandBizFormCode: [
					{
						required: true,
						message: "请选择品牌业态",
						trigger: ["change", "blur"],
					},
				],
				goodOrigin: [
					{
						required: true,
						message: "请选择",
						trigger: "change",
					},
				],
				brandIsPersonal: [
					{
						required: true,
						message: "请选择",
						trigger: "change",
					},
				],
				brandAuthIsDomestic: [
					{
						required: true,
						message: "请选择",
						trigger: "change",
					},
				],
				// 经营资质
				// 20220419 产品同步为非必填项
				// _tradeMarkPic: [
				// 	{
				// 		required: true,
				// 		message: "请上传商标证",
				// 		trigger: "change",
				// 	},
				// ],
				// _authorizationPic: [
				// 	{
				// 		required: true,
				// 		message: "请上传授权书",
				// 		trigger: "change",
				// 	},
				// ],
			},
			specCode: "6000000354165177",
			// 弹窗 - 品牌选择
			selectBrandDialogFlag: false,
			queryBrandParams: {
				zhName: "",
				popLevel: "",
				current: 1,
				pageSize: 10,
				pageTotal: 0,
			},
			brandTableLoading: false,
			brandList: [],
			// 弹窗 - 添加品牌
			newBrandFormDialogFlag: false,
			newBrandForm: {
				zhName: "",
				popLevel: "",
				_logo: [],
				logo: "",
			},
			newBrandFormRules: {
				zhName: [
					{
						required: true,
						message: "请输入品牌名称",
						trigger: "blur",
					},
				],
				popLevel: [
					{
						required: true,
						message: "请选择品牌级别",
						trigger: ["change", "blur"],
					},
				],
			},
			submitNewBrandPendingFlag: false,
			// 经营资质说明
			seniorityDescriptionTable: [
				{
					status: "商标注册证/商品注册申请受理通知书",
					desc: "由国家商标总局颁发的商标注册证（R标）或商标注册申请受理通知书（TM标），可接受商标局查询截图，需加盖公司红章。若商标发生过变更，需上传变更证明。扫描原件或复印件加盖入驻公司红章。",
				},
				{
					status: "其他资质",
					desc: "需提交特殊产品资质的类目请将资质上传在此处，包括全国工业生产许可证、3C、医疗器械生产许可证、化妆品生产企业卫生许可证等，根据具体类目资质要求提交",
				},
			],
			// 操作
			submitPendingFlag: false,
		};
	},
	methods: {
    handleCascaderChanged(value, valueList) {
      const newBrandBizFormCodeSet = new Set()
      this.brandInfoForm._brandBizFormCode = []
      valueList.forEach(item => {
        newBrandBizFormCodeSet.add(item.caId)
        this.brandInfoForm._brandBizFormCode.push(...item.oldCaInfoList.map(i => i.id))
      })
      const newBrandBizFormCodeList = Array.from(newBrandBizFormCodeSet)
      this.brandInfoForm.newBrandBizFormCode = JSON.stringify(newBrandBizFormCodeList)
      this.brandInfoForm._brandBizFormCode = Array.from(new Set(this.brandInfoForm._brandBizFormCode))
    },
		/**
		 * 恢复缓存信息
		 */
		recoveryCache(system = {}) {
			let cache = BarndFrom.get();
			if (system.goodOrigin) {
				// 接口有数据, 忽略缓存数据
				let excludeVal = ["", "null", "undefined"];
				for (let key in this.brandInfoForm) {
					let val = String(system[key]);
					if (!excludeVal.includes(val)) {
						this.brandInfoForm[key] = system[key];
					}
				}
			} else {
				// 接口无数据，恢复缓存数据
				if (cache._already) {
					Object.assign(this.brandInfoForm, cache);
				}
			}

			// 判断  && 更新缓存
			if (this.brandInfoForm.goodOrigin) {
				let temp = {};
				for (let key in this.brandInfoForm) {
					if (
						!key.includes("_") &&
						typeof cache[key] != "undefined"
					) {
						this.$set(temp, key, cache[key]);
					}
				}
				Object.assign(this.brandInfoForm, temp);
				// 格式化中间变量
				this.formatMiddleVariable();
			}
		},
		async getJoinAllFormInfo() {
			try {
				let res = await getJoinAllFormInfo();
				if (res.data.code === 0) {
					if (res.data.data.submitStatus == 2) {
						this.$router.push({
							name: "StatusSubmitted",
						});
					}
					this.recoveryCache(res.data.data);
				} else {
					this.recoveryCache();
				}
			} catch (error) {
				this.recoveryCache();
			}
		},
		/**
		 * 兼容 - 返显
		 */
		async formatMiddleVariable() {
			// 品牌信息
			if (this.brandInfoForm.brandId) {
				getBrandDetailById(this.brandInfoForm.brandId).then(
					async (res) => {
						if (res.data.code === 0) {
							this.handleBrandSelected(res.data.data, true);
							// logo
							if (res.data.data.logoResId) {
								let logoUrl = await getUploadFile(
									res.data.data.logoResId
								);
								this.$set(
									this.brandInfoForm,
									"logo",
									logoUrl.data.data.res.url
								);
							}
						}
					}
				);
			}
			// 中间变量
			this.brandInfoFormMiddleKeyList.map(async (el) => {
				// java变量 ——> 中间变量
				if (
					this.brandInfoForm[el.key] === null ||
					this.brandInfoForm[el.key].length === 0
				) {
					this.brandInfoForm[`_${el.key}`] = [];
				} else {
					try {
						this.brandInfoForm[`_${el.key}`] = el["middleKey"]
							? this.brandInfoForm[el.key].split(",")
							: JSON.parse(this.brandInfoForm[el.key]);
					} catch (err) {
						console.warn(`[${el.key}] 解析异常`);
					}
				}
				// uploader
				if (el["middleKey"]) {
					// 拼装fileList，展示图片
					let len = this.brandInfoForm[`_${el.key}`].length;
					if (len > 0) {
						for (let i = 0; i < len; i++) {
							let id = this.brandInfoForm[`_${el.key}`][i];
							try {
								let url = await getUploadFile(id);
								this.brandInfoForm[el.middleKey].fileList.push({
									url: url.data.data.res.url,
								});
							} catch (error) {
								console.warn("获取图片url失败");
							}
							// 设置 uploader 上传按钮点击状态
							let disabled =
								this.brandInfoForm[el.middleKey].fileList
									.length >=
								this.uploadConfig[el.middleKey].limit
									? true
									: false;
							this.$set(
								this.uploadConfig[el.middleKey],
								"disabled",
								disabled
							);
						}
					}
				}
			});
		},
		/**
		 * 弹窗 - 选择品牌初始化
		 */
		toggleBrandSelectDialogFlag() {
			this.selectBrandDialogFlag = !this.selectBrandDialogFlag;
			this.resetBrandListQueryParams();
		},
		/**
		 * 获取品牌列表
		 */
		getBrandList(byHand) {
			if (byHand) this.queryBrandParams.current = 1;
			this.brandTableLoading = true;
			getBrandList(this.queryBrandParams)
				.then((res) => {
					if (res.data.code === 0) {
						let { records, total } = res.data.data;
						this.brandList = records || [];
						this.queryBrandParams.pageTotal = total;
						// 循环id获取图片url
						this.brandList.map(async (el, index, arr) => {
							if (el.logoResId) {
								let imgInfo = await getUploadFile(el.logoResId);
								this.$set(
									arr[index],
									"logo",
									imgInfo.data.data.res.url
								);
							}
						});
					}
					this.brandTableLoading = false;
				})
				.catch(() => {
					this.brandTableLoading = false;
				});
		},
		resetBrandListQueryParams() {
			this.brandList = [];
			this.queryBrandParams = {
				zhName: "",
				popLevel: "",
				current: 1,
				pageSize: 10,
				total: 0,
			};
			if (this.selectBrandDialogFlag) this.getBrandList();
		},
		handlePageSizeChange(val) {
			this.queryBrandParams.pageSize = val;
			this.getBrandList();
		},
		handlePageNumChange(val) {
			this.queryBrandParams.current = val;
			this.getBrandList();
		},
		/**
		 * 选择品牌
		 */
		handleBrandSelected(item, byInit) {
			// mock 待联调
			let { id: brandId, zhName, popLevel, logo, logoResId } = item;
			Object.assign(this.brandInfoForm, {
				brandId,
				zhName,
				popLevel,
				logo,
				logoResId,
			});
			if (!byInit) this.toggleBrandSelectDialogFlag();
		},
		/**
		 * 弹窗 - 添加新品牌
		 */
		resetNewBrandFormDialogFlag() {
			this.$refs.newBrandForm.resetFields();
			this.$refs.newBrandUpload.clearFiles();
			this.$set(this.uploadConfig._brand, "disabled", false);
		},
		submitNewBrandForm() {
			this.$refs.newBrandForm.validate((valid) => {
				if (valid) {
					this.submitNewBrandPendingFlag = true;
					let newBrandForm = JSON.parse(
						JSON.stringify(this.newBrandForm)
					);
					// 中间变量 -> java变量，且java变量仅发送数据使用
					this.$set(
						newBrandForm,
						"logo",
						newBrandForm._logo.join(",")
					);
					// 删除中间变量
					delete newBrandForm._logo;
					submitNewBrandForm(newBrandForm)
						.then((res) => {
							if (res.data.code === 0) {
								this.toggleNewBrandFormDialogFlag();
								this.resetBrandListQueryParams();
							}
							this.newBrandFormDialogFlag = false;
							this.submitNewBrandPendingFlag = false;
						})
						.catch(() => {
							this.submitNewBrandPendingFlag = false;
						});
				}
			});
		},
		/**
		 * uploader - 公共方法
		 * @param _ref [自定义参数，区分fileList]
		 * @param _key [值为服务端字段，参考接口文档]
		 */
		handleBeforeUpload(file, _ref) {
			let isRightFileType = this.uploadConfig.base.fileType.includes(
				file.type
			);
			if (!isRightFileType) {
				this.$message.warning(`文件格式错误`);
				return false;
			}
			let isRightSize =
				file.size / 1024 / 1024 < this.uploadConfig[_ref].maxSize;
			if (!isRightSize) {
				this.$message.warning(
					`文件不可超过${this.uploadConfig[_ref].maxSize}M`
				);
				return false;
			}
		},
		handleUploadSuccess(res, _ref, _key) {
			let _form = _ref === "_brand" ? "newBrandForm" : "brandInfoForm";
			this[_form][_key].push(res.data.res.id);
			this.$set(
				this.uploadConfig[_ref],
				"disabled",
				this[_form][_key].length >= this.uploadConfig[_ref].limit
			);
		},
		handleFileRemove(file, _ref, _key) {
			let _form = _ref === "_brand" ? "newBrandForm" : "brandInfoForm";
			let uploader = _ref.split("_")[1] + "Upload";
			let uploadFiles = [];
			this.$refs[uploader].uploadFiles.forEach((el) => {
				uploadFiles.push(el.url);
			});
			let removeIndex = uploadFiles.indexOf(file.url);
			if (removeIndex < 0) {
				return false;
			} else {
				this[_form][_key].splice(removeIndex, 1);
				this.$set(this.uploadConfig[_ref], "disabled", false);
			}
		},
		hanleUploadExceed(_ref) {
			this.$message.warning(
				`上传文件不能超过${this.uploadConfig[_ref].limit}个`
			);
		},
		handlePicturePreview(file) {
			this.uploadConfig.base.previewPictureSrc = file.url;
			this.uploadConfig.base.previewPictureFlag = true;
		},
		/**
		 * 重置
		 */
		handleReset() {
			this.$refs.brandInfoForm.resetFields();
			// uploader
			this.brandInfoFormMiddleKeyList.forEach((el) => {
				if (el["ref"]) {
					this.$refs[el.ref].clearFiles();
					this.$set(
						this.uploadConfig[el.middleKey],
						"disabled",
						false
					);
				}
			});
		},
		/**
		 * 上一步
		 */
		jump2beforeStep() {
			let brandInfoForm = this.formatSumbitParams();
			BarndFrom.set({ ...this.brandInfoForm, ...brandInfoForm });
			// 回退
			this.$router.back();
		},
		/**
		 * 提交
		 */
		formatSumbitParams() {
			let brandInfoForm = JSON.parse(JSON.stringify(this.brandInfoForm));
			// 中间变量 -> java变量，且java变量仅发送数据使用
			this.brandInfoFormMiddleKeyList.forEach((el) => {
				this.$set(
					brandInfoForm,
					el.key,
					el["middleKey"]
						? brandInfoForm[`_${el.key}`].join(",")
						: JSON.stringify(brandInfoForm[`_${el.key}`])
				);
			});
			// 删除中间变量
			for (let key in brandInfoForm) {
				if (key.includes("_")) {
					delete brandInfoForm[key];
				}
			}
			return brandInfoForm;
		},
		jump2nextStep() {
			this.$refs.brandInfoForm.validate((valid) => {
				if (valid) {
					this.$confirm("确认提交吗？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
						.then(() => {
							this.submitPendingFlag = true;
							let brandInfoForm = this.formatSumbitParams();
							BarndFrom.set({
								...this.brandInfoForm,
								...brandInfoForm,
							});
							submitBrandInfoForm(brandInfoForm)
								.then((res) => {
									if (res.data.code === 0) {
										this.$router.push({
											name: "StatusSubmitted",
										});
									} else {
										this.$message.warning(res.data.message);
									}
									this.submitPendingFlag = false;
								})
								.catch(() => {
									this.submitPendingFlag = false;
								});
						})
						.catch(() => {
							console.warn("取消提交");
						});
				}
			});
		},
	},
	created() {
		// 同步全量枚举
		this.$store.dispatch("getAllDictionaryList");
		// 获取全量信息
		this.getJoinAllFormInfo();
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.page-step-brand-info {
	.seniorty-table {
		font-size: 12px;
		color: #333333;
		padding: 0 220px 40px 220px;
	}
	.query-btn {
		font-size: 14px !important;
		font-weight: 400;
	}
	.custom-action-box.form {
		justify-content: right;
		margin-top: 0;
	}
	.dialog-footer {
		.btn {
			font-size: 14px !important;
			font-weight: 400 !important;
		}
	}
}
</style>
